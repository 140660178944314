import React from 'react';
import MainTimer from './views/MainPage';

function App() {
  return (
    <MainTimer targetTime={3600000} />
  );
}

export default App;
