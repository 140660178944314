import React from 'react'

interface TimerCardProps {
    value: number;
    label: string;
}

function TimerCard(props: TimerCardProps) {
  return (
    <div className='flex flex-col justify-around items-center w-32 h-32 text-[60px] font-bold p-10 rounded-lg'>
        {props.value}
        <span className='text-xl'>{props.label}</span>
    </div>
  )
}

export default TimerCard