import React, { useState } from 'react';
import Countdown from 'react-countdown';
import Timer from '../components/Timer';
import { passwords } from '../enum/passwords';

interface MainTimerProps {
  targetTime: number;
}

interface RendererProps {
  hours: number;
  minutes: number;
  seconds: number;
  completed: boolean;
}

function MainPage(props: MainTimerProps) {
  const initialTimeStamp = Date.now();
  const [additionnal, setAdditionnal] = useState(0);
  const [inputValue, setInputValue] = useState('');
  const [currentTime, setCurrentTime] = useState(initialTimeStamp);

  const [triedPassword, setTriedPassword] = useState<string[]>([]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.currentTarget.value);
  }

  const handleSubmit = (event: any) => {
    event.preventDefault();
    setInputValue('');
    let found = false;

    triedPassword.map((password) => {
      if (password === inputValue)
        found = true;
    });
    
    if (found)
      return ;

    setTriedPassword((triedPassword) => [...triedPassword, inputValue])
    passwords.map((password) => {
      if (inputValue === password) {
        setAdditionnal(additionnal + 300000);
      }
    });
  }

  return (
    <div className='flex flex-col gap-20 w-full h-screen items-center justify-center bg-[url("/images/background.png")] bg-cover '>
      <Countdown date={currentTime + props.targetTime + additionnal} renderer={Timer} />
      <div className='flex bg-[#1e293b] rounded-xl overflow-hidden border-[2px] border-[#353f4f]'>
        <form action="" className='flex' onSubmit={handleSubmit} >
          <input type="text" name='password' onChange={handleChange} className='p-2 bg-[#1e293b] outline-none text-white text-md font-normal' value={inputValue}/>
          <input type="submit" className='p-2 px-5 font-semibold text-md text-white bg-[#131b2e] hover:bg-[#172033] transition-all' value={"Insérer"}/>
        </form>
      </div>
    </div>
  );
}

export default MainPage;
