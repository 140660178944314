import React from 'react'
import TimerCard from './TimerCard'

interface TimerProps {
    hours: number,
    minutes: number,
    seconds: number,
    completed: boolean
};

function Timer(props: TimerProps) {
  return (
    <div className='flex items-center justify-center text-white flex-col gap-5'>
        <h2 className='font-semibold uppercase text-2xl letter-spacing-2 tracking-[2px]'>Il vous reste</h2>
        <div className='flex gap-5'>
            <TimerCard value={props.hours} label={"heures"} />
            <TimerCard value={props.minutes} label={'minutes'} />
            <TimerCard value={props.seconds} label={'secondes'} />
        </div>
    </div>
  )
}

export default Timer